





















































import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";

@Component
export default class Database extends Vue {
    menuStore: MenuStore = getModule(MenuStore);
    path: string = '/servers/' + this.$route.params['id'] + '/database';
    tab: string = '';

    mounted() {
        this.menuStore.addButtons([
            {
                name: 'Create database',
                modal: 'modal-create-database'

            },
            {
                name: 'Create user',
                modal: 'modal-create-user'

            }
        ])
    }
    get createUserModal() {
        return this.menuStore.currentModal === "modal-create-user";
    }

    set createUserModal(value: boolean) {
        this.menuStore.closeModal();
    }

    get createDatabaseModal() {
        return this.menuStore.currentModal === "modal-create-database";
    }

    set createDatabaseModal(value: boolean) {
        this.menuStore.closeModal();
    }

    beforeDestroy() {
        this.menuStore.clear();
    }
}
